import React from "react";
type table5 = {
    gameBoardMatrix: any
};

const TableFiveFour: React.FC<table5> = ({gameBoardMatrix}) => {
    return (
        <>
            {gameBoardMatrix.map((box: any, index: number) => {
                let top = `${(index * 115) + ((index + 11) * index)}`;
                return (
                    <>
                        {
                            box.map((cell: any, cellIdx: number) => {
                                const left = `${(cellIdx * 182) + ((cellIdx + 10) * cellIdx)}px`;
                                if(index === 7) {
                                    top = `${parseInt(top) - 1}px`;
                                    console.log(top);
                                }  else {
                                    top = `${parseInt(top) + 2}px`;
                                }
                                return (
                                    <div
                                        key={cellIdx + '5_4'}
                                        className="board-text"
                                        style={{ 
                                            top, 
                                            left, 
                                            width: '182px', 
                                            height: '115px', 
                                            display: 'block',
                                            color: '#000',
                                            fontSize: '16px',
                                            fontWeight: 'bold',
                                            textAlign: 'center',
                                            borderRadius: '5px',
                                            position: 'absolute' 
                                        }}
                                    >
                                        <div className='word' style={{
                                            display: "flex",
                                            justifyContent: 'space-between',
                                            alignItems:'baseline',
                                            height: '100%',
                                            flexDirection: 'column'
                                        }}>
                                            <span className="number" 
                                            style={{
                                                alignSelf: 'flex-end',
                                                marginRight: '10px',
                                                fontSize: '15px',
                                                marginTop: '10px'
                                            }}
                                            >{cell.number}</span>
                                            <span className="text" style={{
                                                alignSelf: 'center',
                                                marginBottom: '10px'
                                            }}>{cell.word}</span>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </>
                )
            })}
        </>
    );
}

export default TableFiveFour;