import React, { useEffect } from "react";
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import Tab from "@mui/material/Tab";
import Card from '@mui/material/Card';
import Paper from "@mui/material/Paper";
import CrossWord from "./crossword";
import SnakeLadder from "./snackLadder";



const TopNav = () => {

    const [value, setValue] = React.useState('1');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        console.log('handleChange');
        setValue(newValue);
    };


    return (
        <>
            <Box>
                <AppBar position="static" color="default">
                    {
                        value && 
                        <Tabs className="topNav" value={value} onChange={handleChange} aria-label="" >
                            <Tab label="Snake and Ladder" value="1" />
                            <Tab label="Crossword Puzzles" value="2" />
                        </Tabs>
                    }
                </AppBar>
            </Box>
            <div className="main-content">
                <div className='content'>
            {
                value === '1' &&
                <SnakeLadder />
                
            }
            {
                value === '2' &&
                <CrossWord />
            }
             </div>
            </div>
        </>
    );
}


export default TopNav;