import React from "react";
type table8 = {
    gameBoardMatrix: any
};

const TableEightFive: React.FC<table8> = ({ gameBoardMatrix }) => {
    return (
        <>
            {gameBoardMatrix.map((box: any, index: number) => {
               let top:any = `${(index * 88) + ((index + 11) * index)}`;
                return (
                    <>
                        {
                            box.map((cell: any, cellIdx: number) => {
                                const left = `${(cellIdx * 145) + ((cellIdx + 10) * cellIdx)}px`;
                                if(index === 7) {
                                    top = `${parseInt(top) - 1}px`;
                                    console.log(top);
                                }  else {
                                    top = `${parseInt(top) + 2}px`;
                                }
                                return (
                                    <div 
                                        key={index + cellIdx}
                                        className="board-text"
                                        style={{ 
                                            top, 
                                            left, 
                                            width: '145px', 
                                            height: '88px', 
                                            display: 'block',
                                            color: '#000',
                                            fontSize: '16px',
                                            fontWeight: 'bold',
                                            textAlign: 'center',
                                            borderRadius: '5px',
                                            position: 'absolute' 
                                        }}
                                    >
                                        <div className='word' style={{
                                            display: "flex",
                                            justifyContent: 'space-between',
                                            alignItems:'baseline',
                                            height: '100%',
                                            flexDirection: 'column'
                                        }}>
                                            <span style={{
                                                alignSelf: 'flex-end',
                                                marginRight: '14px',
                                                marginTop: '10px',
                                                fontSize: '15px'
                                            }}>{cell.number}</span>
                                            <span style={{
                                                alignSelf: 'center',
                                                marginBottom: '5px'
                                            }}>{cell.word}</span>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </>
                )
            })}
        </>
    );
}

export default TableEightFive;