
import React, { useState, useEffect } from "react";
import "./App.scss";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import TopNav from "./components/topnav";


function App() {

  return (
    <>
      <TopNav />
    </>
  );
}

export default App;
// git init
// git add .
// git commit -m "Commited by SamuelT"
// git remote add origin https://github.com/cyberaka/ajitkids-ui.git
// git push -u origin branchSamuelT --force