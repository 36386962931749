import React, { useEffect, useRef, useState } from "react";
import CrossWordForm from "./crosswordForm";
import { environment } from "../Constants/environment";
import Typography from '@mui/material/Typography'
import Stack from "@mui/material/Stack";
import ReactToPrint from 'react-to-print';
import Button from "@mui/material/Button";
import PrintIcon from '@mui/icons-material/Print';


interface GameData {
    gameBoard: string[];
    gameLegendList: string[];
}

let height: string = '';
let width: string = '';

const CrossWord = () => {
    const [gameData, setGameData] = useState<GameData | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const componentRef = useRef<HTMLDivElement>(null);
    const formClick = (type: any) => {
        if (type.type === 'generate') {
            fetchData(type.height, type.width);
            updateHW(type.height, type.width);
        }
    }

    const updateHW = (h: string, w: string) => {
        localStorage.setItem('HW', JSON.stringify({h: h, w:w}));
        height = h;
        width = w;
    }

    useEffect(() => {
        const getHW = JSON.parse(localStorage.getItem('HW') || "{}") || {};
        if(Object.keys(getHW).length) {
            updateHW(getHW.h, getHW.w);
            fetchData(getHW.h, getHW.w);
        } else {
            updateHW('15', '15');
            fetchData('15', '15');
        }
    }, [])

    const fetchData = async (height: string, width: string, type?: string) => {
        setLoading(true);
        try {
            const response = await fetch(`${environment.apiURL}word-crossword?height=${height}&width=${width}`);
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            const data: GameData = await response.json();
            setLoading(false);
            setGameData(data);
            if (type === 'print') {
                alert('called');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    return (
        <>
            <CrossWordForm change={formClick} crossWordRef={componentRef} />

            {
                loading &&
                <div className="loader">
                    <div className="spinner"></div>
                </div>
            }
            {
                !loading &&
                <Stack className="crossWordtitle" direction={'row'} gap={2} flexWrap={'wrap'} alignItems={'center'} >
                    <Typography style={{ marginTop: '10px' }} variant="h4" color="initial">Read the words and find them in the table!!</Typography>
                    <ReactToPrint
                        trigger={() => <Button style={{ alignSelf: 'flex-end' }} variant='outlined' startIcon={<PrintIcon />}>Print</Button>}
                        content={() => componentRef.current}
                    />
                </Stack>
            }
            <div ref={componentRef} className="crossWordContent">
                {
                    !loading &&
                    <>
                        <div>
                            <div className="wordsList">
                                {
                                    gameData?.gameLegendList.map((word: string, index: number) => {
                                        return (
                                            <div key={'word' + index} className="chip">
                                                {word}
                                            </div>
                                        );
                                    })
                                }
                            </div>
                            <table className="wordTable">
                                <tbody>
                                    {gameData && gameData.gameBoard.map((row, rowIndex) => (
                                        <tr key={rowIndex}>
                                            {row.split('').map((cell, cellIndex) => (
                                                <td key={cellIndex}>{cell}</td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </>
                }

            </div>
        </>
    );
}


export default CrossWord;