import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = document.getElementById('root');

//@ts-ignore
const rootElement = ReactDOM.createRoot(root);
rootElement.render(
  <React.Fragment>
    <App />
  </React.Fragment>
);

reportWebVitals();
