import React, { useEffect, useRef, useState } from "react";
import SnakeLadderForm from "./snakeLadderForm";
import { environment } from "../Constants/environment";
import Typography from '@mui/material/Typography'
import { Divider, Stack } from "@mui/material";

import ReactToPrint from 'react-to-print';
import Button from "@mui/material/Button";
import PrintIcon from '@mui/icons-material/Print';
import TableEightFive from "./tableEightFive";
import TableFiveFour from "./tableFiveFour";


const img5 = require('./../components/docs/GameBoard-5x4.png');
const img8 = require('./../components/docs/GameBoard-8x5.png');


const SnakeLadder = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const componentRef = useRef<HTMLDivElement>(null);
    const [gameBoardMatrix, setGameBoardMatrix] = useState<any>({});

    useEffect(() => {
        const snakeLadderObj = JSON.parse(localStorage.getItem('snackLadder') || "{}") || {};
        if(Object.keys(snakeLadderObj).length) {
            fetchData(snakeLadderObj);
        } else {
            const defaultReqObj = {
                "subject": "Phonetics",
                "wordBankGroupNum": "Group 1",
                "phoneticsToInclude": "a,t,i,p,n",
                "largeText": false,
                "height": 5,
                "width": 4
            }
            fetchData(defaultReqObj);
        }
    }, [])

    const fetchData = async (request: any) => {
        setLoading(true);
        try {
            const response = await fetch(`${environment.apiURL}snake-ladder?subject=${request.subject}&height=${request.height}&width=${request.width}&wordBankGroupNum=${request.wordBankGroupNum}&phoneticsToInclude=${request.phoneticsToInclude}&largeText=${request.largeText}`);
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            const data = await response.json();
            setLoading(false);
            setGameBoardMatrix(data);
            localStorage.setItem('snackLadder', JSON.stringify(request));
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    }

    const submitForm = (reqObj: any) => {
        fetchData(reqObj);
    }
    return (
        <>
            <SnakeLadderForm change={submitForm} />
            {
                loading &&
                <div className="loader">
                    <div className="spinner"></div>
                </div>
            }
            {
                !loading && Object.keys(gameBoardMatrix).length &&
                <div className="snackLadderMainView">
                    <Divider></Divider>
                    <div>
                        <Stack direction={'row'} gap={3} alignItems={'center'}>
                            <span>
                                <Typography variant="h5" color="initial">Group: {gameBoardMatrix.subject}</Typography>
                                <Typography variant="h6" color="initial">Sub Group:  {gameBoardMatrix.subGroup}</Typography>
                            </span>
                            <ReactToPrint
                                trigger={() => <Button variant='outlined' startIcon={<PrintIcon />}>Print</Button>}
                                content={() => componentRef.current}
                            />
                        </Stack>
                        <div ref={componentRef} className="content_view">
                            {
                                gameBoardMatrix.height === 5 &&
                                <>
                                    <img src={img5} alt="Snakes and Ladders" className="board-image" />
                                    <TableFiveFour gameBoardMatrix={gameBoardMatrix.gameBoardMatrix}/>
                                </>
                            }
                            {
                                gameBoardMatrix.height === 8 &&
                                <>
                                    <img src={img8} alt="Snakes and Ladders" className="board-image" />
                                    <TableEightFive gameBoardMatrix={gameBoardMatrix.gameBoardMatrix}/>
                                </>
                            }
                        </div>
                    </div>
                </div>
            }
        </>
    );
}
export default SnakeLadder;