import Box from "@mui/material/Box";
import Grid from '@mui/material/Grid2';
import React, { useEffect, useState } from "react";
import Button from '@mui/material/Button';
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import { Message } from "../Constants/Message";
import ReactToPrint from "react-to-print";

type ButtonProps = {
    change: ({ }) => void; // explicit typing
    crossWordRef: any
};

const CrossWordForm: React.FC<ButtonProps> = ({ change, crossWordRef }) => {
    const [height, setHeight] = useState('')
    const [width, setWidth] = useState('')
    const [showSnackBar, setShowSnackBar] = useState(false)

    useEffect(() => {
        if (showSnackBar) {
            setTimeout(() => {
                setShowSnackBar(false)
            }, 2000);
        }
    }, [showSnackBar]);

    useEffect(() => {
        const getHW = JSON.parse(localStorage.getItem('HW') || "{}") || {};
        if(Object.keys(getHW).length) {
            setHeight(getHW.h);
            setWidth(getHW.w);
        } 
    }, []);



    const textChange = (ev: any) => {
        if (ev.target.name === 'height') {
            setHeight(ev.target.value)
        } else {
            setWidth(ev.target.value)
        }
    }


    const generateClick = (type: string) => {
        const h = parseInt(height) || '';
        const w = parseInt(width) || '';
        if (!h) {
            setShowSnackBar(true)
        } else if (!w) {
            setShowSnackBar(true)
        } else {
            change({ type: type, height, width })
        }
    }


    return (
        <>
            <Box className="wordForm">
                <Stack direction={'row'} gap={1} alignItems={'center'} flexWrap={'wrap'} sx={{ justifyContent: {xs: 'center', md: 'inherit'} }}>
                            <TextField
                                fullWidth={false}
                                id="filled-number"
                                label="Height"
                                name="height"
                                type="number"
                                variant="standard"
                                value={height}
                                onChange={textChange}
                                slotProps={{
                                    inputLabel: {
                                        shrink: true,
                                    },
                                }}
                            />
                            <TextField
                            fullWidth={false}
                                id="filled-number"
                                label="Width"
                                name="width"
                                type="number"
                                variant="standard"
                                onChange={textChange}
                                value={width}
                                slotProps={{
                                    inputLabel: {
                                        shrink: true,
                                    },
                                }}
                            />
                            <Stack spacing={3} direction="row" className="formBtnGroup">
                                <Button variant='outlined' onClick={() => generateClick('generate')}>Generate</Button>
                               
                                
                            </Stack>
                </Stack>
            </Box>
            <Snackbar
                open={showSnackBar}
                message={Message.WORDERR}
            />
        </>
    );
}


export default CrossWordForm;