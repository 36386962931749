import React, { useEffect, useState } from "react";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { Button, Checkbox, FormControlLabel, Snackbar, Stack } from "@mui/material";
import { environment } from "../Constants/environment";

type snakeLadder = {
    change: ({ }) => void; // explicit typing
};

const SnakeLadderForm: React.FC<snakeLadder> = ({ change }) => {
    const [subjects, setSubjects] = useState([]);
    const [selectedSubject, setSelectedSubject] = useState("");
    const [groups, setGroups] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState("");
    const [subgroups, setSubgroups] = useState([]);
    const [selectedSubgroup, setSelectedSubgroup] = useState("");
    const [isChecked, setIsChecked] = useState(false);
    const [showSnackBar, setShowSnackBar] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [size, setSize] = useState("");

    useEffect(() => {
        fetchSubjects();
    }, [])

    useEffect(() => {
        const snakeLadderObj = JSON.parse(localStorage.getItem('snackLadder') || "{}") || {};
        if (Object.keys(snakeLadderObj).length) {
            setSelectedSubject(snakeLadderObj.subject);
            const subject: any = subjects.find((sub: any) => sub.name === snakeLadderObj.subject);
            if (subject) {
                setGroups(subject.groups);
                const subgroup: any = subject.groups.find((grp: any) => grp.name === snakeLadderObj.wordBankGroupNum);
                if (subgroup) {
                    setSubgroups(subgroup.subgroups);
                }
            }
            setSelectedGroup(snakeLadderObj.wordBankGroupNum);
            setSelectedSubgroup(snakeLadderObj.phoneticsToInclude);
            if(snakeLadderObj.height === 8) {
                setSize("1");
            } else {
                setSize("2");
            }
            setIsChecked(snakeLadderObj.largeText)
        }
    }, [subjects])

    useEffect(() => {
        const subject: any = subjects.find((sub: any) => sub.name === selectedSubject);
        if (subject) {
            setGroups(subject.groups);
            setSubgroups([]);
            setSelectedGroup("");
            setSelectedSubgroup("");
        }
    }, [selectedSubject])

    useEffect(() => {
        if (showSnackBar) {
            setTimeout(() => {
                setShowSnackBar(false)
            }, 2000);
        }
    }, [showSnackBar]);

    const subjectChange = (event: SelectChangeEvent) => {
        if (event.target.value) {
            setSelectedSubject(event.target.value as string);
        } else {
            setSelectedSubject('');
            setGroups([]);
            setSelectedGroup("");
            setSubgroups([]);
            setSelectedSubgroup("");
        }
    };

    const groupChange = (event: SelectChangeEvent) => {
        const selectedGroup = event.target.value;
        if (selectedGroup) {
            setSelectedGroup(selectedGroup);

            // Find the group from the selected subject's groups array
            const subgroup: any = groups.find((grp: any) => grp.name === selectedGroup);
            if (subgroup) {
                setSubgroups(subgroup.subgroups);
                setSelectedSubgroup("");
            }
        } else {
            setSelectedGroup("");
            setSubgroups([]);
            setSelectedSubgroup("");
        }
    };

    const subGroupChange = (event: SelectChangeEvent) => {
        setSelectedSubgroup(event.target.value);
    };

    const sizeChange = (event: SelectChangeEvent) => {
        setSize(event.target.value);
    };

    const fetchSubjects = async () => {
        try {
            const response = await fetch(`${environment.apiURL}snake-ladder/subjects`);
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            const data: any = await response.json();
            setSubjects(data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const largeTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsChecked(event.target.checked);
    };

    const generateClick = () => {
        if (!selectedSubject) {
            setShowSnackBar(true);
            setErrMsg('Select Subject');
        } else if (!selectedGroup) {
            setShowSnackBar(true);
            setErrMsg('Select Group');
        } else if (!selectedSubgroup) {
            setShowSnackBar(true);
            setErrMsg('Select Sub Group');
        } else {
            let h: number, w: number;
            if (size === "1") {
                h = 8;
                w = 5;
            } else {
                h = 5;
                w = 4;
            }
            change({ subject: selectedSubject, wordBankGroupNum: selectedGroup, phoneticsToInclude: selectedSubgroup, largeText: isChecked, height: h, width: w })
        }
    }

    return (
        <>
            <Stack direction={'row'} gap={3} className="snakeLadder" flexWrap={'wrap'} sx={{ justifyContent: { xs: 'center', md: 'inherit' } }} alignItems={'center'}>
                <FormControl className="custom-form" variant="standard" >
                    <InputLabel id='demo-simple-select-label'>Subject</InputLabel>
                    <Select
                        labelId='demo-simple-select-label'
                        id='demo-simple-select'
                        label='Subject'
                        value={selectedSubject}
                        onChange={subjectChange}
                    >
                        <MenuItem value=''>Select Subject</MenuItem>
                        {subjects.map((subject: any) => (
                            <MenuItem key={subject.name + 'subject'} value={subject.name}> {subject.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl className="custom-form" variant="standard" >
                    <InputLabel id='demo-simple-select-label'>Group</InputLabel>
                    <Select
                        labelId='demo-simple-select-label'
                        id='demo-simple-select'
                        label='Group'
                        value={selectedGroup}
                        onChange={groupChange}
                        disabled={!selectedSubject}
                    >
                        <MenuItem value=''>Select Group</MenuItem>
                        {groups.map((subject: any) => (
                            <MenuItem key={subject.name + 'subject'} value={subject.name}> {subject.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl className="custom-form" variant="standard" >
                    <InputLabel id='demo-simple-select-label'>Sub Group</InputLabel>
                    <Select
                        labelId='demo-simple-select-label'
                        id='demo-simple-select'
                        label='Sub Group'
                        value={selectedSubgroup}
                        onChange={subGroupChange}
                        disabled={!selectedGroup}
                    >
                        <MenuItem value=''>Select Subgroup</MenuItem>
                        {subgroups.map((subject: any) => (
                            <MenuItem key={subject + 'subject'} value={subject}> {subject}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl className="custom-form" variant="standard" >
                    <InputLabel id='demo-simple-select-label'>Size</InputLabel>
                    <Select
                        labelId='demo-simple-select-label'
                        id='demo-simple-select'
                        label='Sub Group'
                        value={size}
                        onChange={sizeChange}
                    >
                        <MenuItem value={"1"}>8 * 5</MenuItem>
                        <MenuItem value={"2"}>5 * 4</MenuItem>
                    </Select>
                </FormControl>
                <FormControlLabel control={<Checkbox checked={isChecked}
                    onChange={largeTextChange} />} label="Large Text" />
                <Button variant='outlined' onClick={generateClick}>Generate</Button>
            </Stack>
            <Snackbar
                open={showSnackBar}
                message={errMsg}
            />
        </>
    );
}

export default SnakeLadderForm;